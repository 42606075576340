// src/components/Home.js
import React, { useState } from 'react';
import '../styles/Home.css';

const Home = () => {
    // const [isClosed, setIsClosed] = useState(true); // true if the shop is closed

    return (
        <div className="home">
            {/* {isClosed && (
                <div className="closed-message">
                    {<p>Liebe Kunden, wir haben vom 27. Dezember bis einschließlich 1. Januar geschlossen. Habt ein frohes neues Jahr</p>}
                </div>
            )} */}
            <div className="hero">
                <img src="/images/her2.jpg" alt="Fresh bakery" />
                <div className="hero-text">
                    <h2>Willkommen bei Backshop By Hamo</h2>
                    <p>Genießen Sie unsere frischen, mit Liebe hergestellten Backwaren</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
