import React, { useState } from 'react';
import '../styles/Menu.css';

const Menu = () => {
    // State to manage selected category
    const [category, setCategory] = useState('Sandwich');

    // Sample menu data with categories and size options
    const menuItems = [
        { id: 1, category: 'Panini', name: 'Panini Churancy Chicken Dunkel/Hell', price: '€4.90', image: '/images/panini.jpg' },
        { id: 2, category: 'Panini', name: 'Panini Churancy Chicken und Mozzarella Dunkel/Hell', price: '€4.90', image: '/images/Panini Churancy Chicken und Mozzarella.jpg' },
        { id: 3, category: 'Panini', name: 'Tomaten & Mozzarella Panino Dunkel/Hell', price: '€4.90', image: '/images/Tomaten & Mozzarella Panino.jpg' },
        { id: 4, category: 'Panini', name: 'Putenbrust & Gouda Panino Dunkel/Hell', price: '€4.90', image: '/images/Putenbrust & Gouda Panino.jpg' },
        { id: 5, category: 'Panini', name: 'Panini Salami Gouda Dunkel/Hell', price: '€4.90', image: '/images/Panini Salami Gouda.jpg' },
        { id: 6, category: 'Panini', name: 'Sucuk & Gouda Panino Dunkel/Hell', price: '€4.90', image: '/images/Sucuk & Gouda Panino.jpg' },
        { id: 7, category: 'Panini', name: 'Vegan Antipasti & Hummus Panino Dunkel/Hell', price: '€4.90', image: '/images/Vegan Antipasti & Hummus Panino.jpg' },

        { id: 8, category: 'WRAPS', name: 'Big Hähnchen & Tomate Wrap', price: '€4.50', image: '/images/Big Hähnchen & Tomate Wrap.jpg' },
        { id: 9, category: 'WRAPS', name: 'Big Crunchy Chicken Wrap', price: '€4.50', image: '/images/Big Hähnchen & Tomate Wrap.jpg' },
        { id: 10, category: 'WRAPS', name: 'Big Thunfisch Wrap', price: '€4.50', image: '/images/Big Hähnchen & Tomate Wrap.jpg' },
        { id: 11, category: 'WRAPS', name: 'Vegan Antipasti & Hummus Wrap', price: '€4.50', image: '/images/Big Hähnchen & Tomate Wrap.jpg' },
        { id: 12, category: 'WRAPS', name: 'Vegan Big Wrap Falafel', price: '€4.50', image: '/images/Big Hähnchen & Tomate Wrap.jpg' },


        //{ id: 13, category: 'BAGELS', name: 'Big Bagel mit Frischkäse', price: { small: '€1.00', medium: '€1.50', large: '€2.00' }, image: '/images/coffee.jpg', hasSizes: true },
        //{ id: 4, category: 'Drinks', name: 'Tea', price: { small: '€0.80', medium: '€1.20', large: '€1.50' }, image: '/images/tea.jpg', hasSizes: true },
        { id: 13, category: 'BAGELS', name: 'Big Bagel mit Frischkäse', price: '€4.50', image: '/images/Big Bagel mit Frischkäse.jpg' },
        { id: 14, category: 'BAGELS', name: 'Big Salmon-Lachs Bagel', price: '€4.50', image: '/images/Big Salmon-Lachs Bagel.jpg' },
        { id: 15, category: 'BAGELS', name: 'Bin Bagel mit Pute Frisch Käse', price: '€4.50', image: '/images/Bin Bagel mit Pute Frisch Käse.jpg' },


        { id: 16, category: 'SANDWICHES', name: 'Laugen mit Tomaten Mozzarella', price: '€3.90', image: '/images/Laugen mit Tomaten Mozzarella.jpg' },
        { id: 17, category: 'SANDWICHES', name: 'Laugen mit Käse Gemüse', price: '€3.90', image: '/images/Laugen mit Käse Gemüse.jpg' },
        { id: 18, category: 'SANDWICHES', name: 'Laugen mit Salami Gemüse', price: '€3.90', image: '/images/Laugen mit Salami Gemüse.jpg' },
        { id: 23, category: 'SANDWICHES', name: 'Laugen mit Pute', price: '€3.90', image: '/images/Laugen mit Pute.jpg' },
        { id: 19, category: 'SANDWICHES', name: 'Sandwich mit Rürhr Ei', price: '€3.00', image: '/images/Sandwich mit Rürhr Ei.jpg' },
        { id: 20, category: 'SANDWICHES', name: 'Sandwich Gouda', price: '€3.00', image: '/images/Sandwich Gouda.jpg' },
        { id: 21, category: 'SANDWICHES', name: 'Salami Sandwich', price: '€3.00', image: '/images/Salami Sandwich.jpg' },
        { id: 22, category: 'SANDWICHES', name: 'Pute Sandwich', price: '€3.00', image: '/images/Pute Sandwich.jpg' },


        { id: 24, category: 'AMERIKANISCHE BÄCKEREI', name: 'Blueberry-Muffin', price: '€2.50', image: '/images/Blueberry-Muffin.jpg' },
        { id: 25, category: 'AMERIKANISCHE BÄCKEREI', name: 'Schokodonut', price: '€1.50', image: '/images/Schokodonut.jpg' },
        { id: 26, category: 'AMERIKANISCHE BÄCKEREI', name: 'Schoko-Brownie', price: '€2.50', image: '/images/Schoko-Brownie.jpg' },
        { id: 27, category: 'AMERIKANISCHE BÄCKEREI', name: 'Schweineohr', price: '€2.50', image: '/images/Schweineohr.jpg' },
        { id: 28, category: 'AMERIKANISCHE BÄCKEREI', name: 'Amerikaner', price: '€2.50', image: '/images/Amerikaner.jpg' },
        { id: 29, category: 'AMERIKANISCHE BÄCKEREI', name: 'Franzbrötchen', price: '€1.80', image: '/images/Franzbrötchen.jpg' },
        { id: 30, category: 'AMERIKANISCHE BÄCKEREI', name: 'Dunkler Cookie', price: '€1.50', image: '/images/Dunkler Cookie.jpg' },
        { id: 31, category: 'AMERIKANISCHE BÄCKEREI', name: 'Walnuss Brownie', price: '€3.00', image: '/images/Walnuss Brownie.jpg' },
        { id: 32, category: 'AMERIKANISCHE BÄCKEREI', name: 'Hell Cookie', price: '€1.50', image: '/images/Hell Cookie.jpg' },
        { id: 33, category: 'AMERIKANISCHE BÄCKEREI', name: 'Mandel Bienenstich', price: '€3.00', image: '/images/Mandel Bienenstich.jpg' },
        { id: 34, category: 'AMERIKANISCHE BÄCKEREI', name: 'Puddingbrezel', price: '€2.50', image: '/images/Puddingbrezel.jpg' },
        { id: 35, category: 'AMERIKANISCHE BÄCKEREI', name: 'Streuselschnecke mit Vanillefüllung', price: '€2.50', image: '/images/Streuselschnecke mit Vanillefüllung.jpg' },
        { id: 36, category: 'AMERIKANISCHE BÄCKEREI', name: 'Zuckerwaffel', price: '€1.80', image: '/images/Zuckerwaffel.jpg' },
        { id: 37, category: 'AMERIKANISCHE BÄCKEREI', name: 'Pekannuss tasche', price: '€2.00', image: '/images/Pekannuss tasche.jpg' },
        { id: 38, category: 'AMERIKANISCHE BÄCKEREI', name: 'Rosinenschnecke', price: '€1.50', image: '/images/Rosinenschnecke.jpg' },
        { id: 39, category: 'AMERIKANISCHE BÄCKEREI', name: 'Apfeltasche', price: '€2.00', image: '/images/Apfeltasche.jpg' },

        { id: 40, category: 'CROISSANTERIE', name: 'Butter Croissant', price: '€1.30', image: '/images/Butter Croissant.jpg' },
        { id: 41, category: 'CROISSANTERIE', name: 'Croissant Nuss-Nougat-Füllung', price: '€1.80', image: '/images/Croissant Nuss-Nougat-Füllung.jpg' },

        { id: 42, category: 'SALATE', name: 'Nudelsalat Italienisch', price: '€3.50', image: '/images/Nudelsalat Italienisch.jpg' },

        { id: 43, category: 'MILCHREIS', name: 'Milchreis', price: '€3.50', image: '/images/Milchreis.jpg' },

        { id: 44, category: 'HAUSGEMACHTE GETRÄNKE', name: 'milchshake mit vanile 400/500ml', price: '€4.50', image: '/images/milchshake mit vanile.jpg' },
        { id: 45, category: 'HAUSGEMACHTE GETRÄNKE', name: 'Schoko Milchshake 400/500ml', price: '€4.50', image: '/images/milchshake mit vanile.jpg' },
        { id: 46, category: 'HAUSGEMACHTE GETRÄNKE', name: 'Erdbeeren Milchshake 400/500ml', price: '€4.50', image: '/images/milchshake mit vanile.jpg' },
        { id: 47, category: 'HAUSGEMACHTE GETRÄNKE', name: 'Eis latte Latte Macchiato 400/500ml', price: '€4.50', image: '/images/Eis Carmel Latte Macchiato.jpg' },
        { id: 48, category: 'HAUSGEMACHTE GETRÄNKE', name: 'Eis Carmel Latte Macchiato 400/500ml', price: '€5.00', image: '/images/Eis Carmel Latte Macchiato.jpg' },
        { id: 49, category: 'HAUSGEMACHTE GETRÄNKE', name: 'Eis Vanille Latte Macchiato 400/500ml', price: '€5.00', image: '/images/Eis Carmel Latte Macchiato.jpg' },

        { id: 50, category: 'Heiße Getränke', name: 'Cafe crema', price: { small: '€1.90', medium: '', large: '€2.90' }, image: '/images/Cafe crema.jpg', hasSizes: true },
        { id: 59, category: 'Heiße Getränke', name: 'Dupell Espresso', price: { small: '€1.90', medium: '', large: '€2.90' }, image: '/images/Cafe crema.jpg', hasSizes: true },
        { id: 51, category: 'Heiße Getränke', name: 'Cafe au Lait & milch Kaffe', price: { small: '', medium: '', large: '€3.50' }, image: '/images/Cafe au Lait & milch Kaffe.jpg', hasSizes: true },
        { id: 52, category: 'Heiße Getränke', name: 'Cappuccino', price: { small: '€2.90', medium: '€3.90', large: '' }, image: '/images/Cafe au Lait & milch Kaffe.jpg', hasSizes: true },
        { id: 53, category: 'Heiße Getränke', name: 'Latte Macchiato', price: { small: '', medium: '€3.50', large: '€4.50' }, image: '/images/Cafe au Lait & milch Kaffe.jpg', hasSizes: true },
        { id: 54, category: 'Heiße Getränke', name: 'Hot & Heiße Schokolade', price: { small: '', medium: '', large: '€3.50' }, image: '/images/Caramel Cappuccino.jpg', hasSizes: true },
        { id: 55, category: 'Heiße Getränke', name: 'Caramel Cappuccino', price: { small: '€3.50', medium: '€4.50', large: '' }, image: '/images/Caramel Cappuccino.jpg', hasSizes: true },
        { id: 56, category: 'Heiße Getränke', name: 'Vanille Cappuccino', price: { small: '€3.50', medium: '€4.50', large: '' }, image: '/images/Caramel Cappuccino.jpg', hasSizes: true },
        { id: 57, category: 'Heiße Getränke', name: 'Caramel Latte Macchiato', price: { small: '', medium: '€4.10', large: '€5.10' }, image: '/images/Cafe au Lait & milch Kaffe.jpg', hasSizes: true },
        { id: 58, category: 'Heiße Getränke', name: 'Vanille Latte Macchiato', price: { small: '', medium: '€4.10', large: '€5.10' }, image: '/images/Cafe au Lait & milch Kaffe.jpg', hasSizes: true },
        { id: 60, category: 'Heiße Getränke', name: 'Tee', price: '€1.90', image: '/images/tee.jpg' },


        { id: 61, category: 'ALKOHOLFREIE GETRÄNKE', name: '28 Black Acai 0,25l', price: '€2.50', image: '/images/28 Black Acai 0,25l.jpg' },
        { id: 62, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Red Bull Energy Drink Saugarfree 0,25 l', price: '€2.50', image: '/images/Red Bull Energy Drink Saugarfree 0,25 l.jpg' },
        { id: 63, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Red Bull Energy Drink 0,25 l', price: '€2.50', image: '/images/Red Bull Energy Drink 0,25 l.jpg' },
        { id: 64, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Coca-Cola Zero 0,33', price: '€2.00', image: '/images/Coca-Cola Zero 0,33.jpg' },
        { id: 65, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Still Wasser 0,5I', price: '€2.00', image: '/images/Still Wasser 0,5I.jpg' },
        { id: 66, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Fanta 0,33 l', price: '€2.00', image: '/images/Fanta 0,33 l.jpg' },
        { id: 67, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Vio spritzig 0,5I', price: '€2.00', image: '/images/Vio spritzig 0,5I.jpg' },
        { id: 68, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Coca-Cola® 0,33 l', price: '€2.00', image: '/images/Coca-Cola® 0,33 l.jpg' },
        { id: 69, category: 'ALKOHOLFREIE GETRÄNKE', name: 'Vio Bio Limo Orange 0,33 l', price: '€2.50', image: '/images/Vio Bio Limo Orange 0,33 l.jpg' },

        { id: 70, category: 'Frühstück', name: 'Frühstück 1', price: '€9.90', image: '/images/Frühstück4.jpg' },
        { id: 71, category: 'Frühstück', name: 'Frühstück 2', price: '€9.90', image: '/images/Frühstück3.jpg' },
        { id: 72, category: 'Frühstück', name: 'Frühstück 3', price: '€9.90', image: '/images/Frühstück2.jpg' },
        { id: 73, category: 'Frühstück', name: 'Frühstück 4', price: '€10.90', image: '/images/Frühstück1.jpg' },
    ];

    // Filter menu items by selected category
    const filteredItems = menuItems.filter(item => item.category === category);

    return (
        <div className="menu">
            <h2>Unser Menü</h2>
            {/* Category Buttons */}
            <div className="menu-categories">
                <button onClick={() => setCategory('Frühstück')} className={category === 'Frühstück' ? 'active' : ''}>
                    Frühstück
                </button>
                <button onClick={() => setCategory('Panini')} className={category === 'Panini' ? 'active' : ''}>
                    Panini
                </button>
                <button onClick={() => setCategory('WRAPS')} className={category === 'WRAPS' ? 'active' : ''}>
                    WRAPS
                </button>
                <button onClick={() => setCategory('BAGELS')} className={category === 'BAGELS' ? 'active' : ''}>
                    BAGELS
                </button>
                <button onClick={() => setCategory('SANDWICHES')} className={category === 'SANDWICHES' ? 'active' : ''}>
                    SANDWICHES
                </button>
                <button onClick={() => setCategory('AMERIKANISCHE BÄCKEREI')} className={category === 'AMERIKANISCHE BÄCKEREI' ? 'active' : ''}>
                    AMERIKANISCHE BÄCKEREI
                </button>
                <button onClick={() => setCategory('CROISSANTERIE')} className={category === 'CROISSANTERIE' ? 'active' : ''}>
                    CROISSANTERIE
                </button>
                <button onClick={() => setCategory('SALATE')} className={category === 'SALATE' ? 'active' : ''}>
                    SALATE
                </button>
                <button onClick={() => setCategory('MILCHREIS')} className={category === 'MILCHREIS' ? 'active' : ''}>
                    MILCHREIS
                </button>
                <button onClick={() => setCategory('HAUSGEMACHTE GETRÄNKE')} className={category === 'HAUSGEMACHTE GETRÄNKE' ? 'active' : ''}>
                    HAUSGEMACHTE GETRÄNKE
                </button>
                <button onClick={() => setCategory('Heiße Getränke')} className={category === 'Heiße Getränke' ? 'active' : ''}>
                    Heiße Getränke
                </button>
                <button onClick={() => setCategory('ALKOHOLFREIE GETRÄNKE')} className={category === 'ALKOHOLFREIE GETRÄNKE' ? 'active' : ''}>
                    ALKOHOLFREIE GETRÄNKE
                </button>
            </div>

            {/* Menu Items */}
            <div className="menu-items">
                {filteredItems.map(item => (
                    <div className="menu-item" key={item.id}>
                        <img src={item.image} alt={item.name} />
                        <p>{item.name}</p>

                        {/* Show sizes if available */}
                        {item.hasSizes ? (
                            <div className="menu-sizes">
                                <p>Klein: {item.price.small}</p>
                                <p>Mittel: {item.price.medium}</p>
                                <p>Gross: {item.price.large}</p>
                            </div>
                        ) : (
                            <p>{item.price}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Menu;
